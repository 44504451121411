.wrapper{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 48px 0;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  .title{
    font-size: 35px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 500;
  }
  p{
    text-align: center;
    max-width: 900px;
  }
}