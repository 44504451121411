.whiteTheme {
  background-color: #ffffff;
  color: #000000;
}

.blackTheme {
  background-color: #000000;
  color: #ffffff;
}

.wrapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 40px;
  padding: 40px;
  gap: 40px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .pageTitle {
      font-weight: 500;
      font-family: "Dancing Script", "sans-serif";
      font-size: 70px;
      opacity: 0.5;
    }
  }

  .body{
    display: flex;
    flex-direction: row;
    flex: 1;
    .category {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      gap: 40px;
      .title {
        font-size: 80px;
        text-align: left;
      }
    }
  }

  .footer{
    font-size: 20px;
    opacity: 0.5;
  }


}