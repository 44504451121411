.wrapper {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 640px) {
  .wrapper {
    padding: 60px 0;
    flex-direction: column;
    gap: 40px;
  }
}
.wrapper .navigation {
  display: flex;
  gap: 100px;
}
@media (max-width: 640px) {
  .wrapper .navigation {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

