.slide {
  height: 500px;
  width: 100%;
  background-size: cover;
  background: url("/public/images/landing.webp") center;
  display: flex;
  flex-direction: column;
}
.slide .titleWrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.slide .titleWrapper .title {
  font-family: "Dancing Script", "sans-serif";
  color: #ffffff;
  text-align: center;
  font-size: 140px;
}
@media (max-width: 640px) {
  .slide .titleWrapper .title {
    font-size: 40px;
  }
}

